import { useAppFilters } from 'web/contexts/AppFiltersContext';

import TaskDeadlineEntryInPeriod from 'web/components/companiesArchive/cells/TaskDeadlineEntryInPeriod';
import TaskDeadlineEntriesInYear from './TaskDeadlineEntriesInYear';

export default function TaskDeadlineEntryStatus({
	companyId,
	task,
	setEditAssignmentEntry,
}) {
	const { year, month } = useAppFilters();

	if (Number.isNaN(!year?.value) || Number.isNaN(!month?.value) || !task) {
		return null;
	}

	if (
		task === 'Aksjonærregisteroppg.' ||
		task === 'Avholdt Generalforsamling' ||
		task === 'Planlagt levert revisor'
	) {
		return (
			<TaskDeadlineEntriesInYear
				companyId={companyId}
				taskName={task}
				year={year.value}
				setEditAssignmentEntry={setEditAssignmentEntry}
			/>
		);
	}

	return (
		<TaskDeadlineEntryInPeriod
			companyId={companyId}
			taskName={task}
			month={month.value}
			year={year.value}
			setEditAssignmentEntry={setEditAssignmentEntry}
		/>
	);
}

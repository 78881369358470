export const defaultAssignmentTasks = [
	{
		category: { name: 'Viktige oppgaver', order: 1 },
		tasks: [
			{
				name: 'Avstemt mva.',
				type: 'GENERAL',
				defaultActive: true,
				notifications: false,
				globalRoutineFilename: 'avstem-mva.md',
				subTasks: [
					{ name: 'Avstem mva. i avstemmingskjema mot hovedbok' },
				],
			},
			{
				name: 'Innsendt mva.',
				type: 'GENERAL',
				defaultActive: true,
				notifications: true,
				globalRoutineFilename: 'innsendt-mva.md',
				frequency: 'custom',
				deadline: {
					dates: [
						{ day: 10, month: 1 }, // 10. february
						{ day: 10, month: 3 }, // 10. april
						{ day: 10, month: 5 }, // 10. june
						{ day: 31, month: 7 }, // 31. august
						{ day: 10, month: 9 }, // 10. october
						{ day: 10, month: 11 }, // 10. december
					],
				},
				subTasks: [{ name: 'Kontroller at mva. er innbetalt' }],
			},
			{
				name: 'Innsendt mva. (Årlig frist)',
				type: 'GENERAL',
				defaultActive: false,
				notifications: true,
				globalRoutineFilename: 'innsendt-mva.md',
				frequency: 'yearly',
				deadline: {
					day: 10, // 10. march
					month: 2,
				},
				subTasks: [{ name: 'Kontroller at mva. er innbetalt' }],
			},
			{
				name: 'Innsendt mva. (Primærnæring)',
				type: 'GENERAL',
				defaultActive: false,
				notifications: true,
				globalRoutineFilename: 'innsendt-mva.md',
				frequency: 'custom',
				deadline: {
					dates: [
						{ day: 10, month: 1 }, // 10. february
						{ day: 10, month: 3 }, // 10. april
						{ day: 10, month: 5 }, // 10. june
						{ day: 31, month: 7 }, // 31. august
						{ day: 10, month: 9 }, // 10. october
						{ day: 10, month: 11 }, // 10. december
					],
				},
				subTasks: [{ name: 'Kontroller at mva. er innbetalt' }],
			},
			{
				name: 'Innsendt mva. (Primærnæring, årlig frist)',
				type: 'GENERAL',
				defaultActive: false,
				notifications: true,
				globalRoutineFilename: 'innsendt-mva.md',
				frequency: 'yearly',
				deadline: {
					day: 10, // 10. april
					month: 3,
				},
				subTasks: [{ name: 'Kontroller at mva. er innbetalt' }],
			},
			{
				name: 'Rapportering',
				type: 'GENERAL',
				defaultActive: true,
				notifications: false,
				globalRoutineFilename: 'rapportering.md',
				subTasks: [
					{
						name: 'Utarbeid regnskapsrapport i henhold til oppdragsavtale',
					},
				],
			},
			{
				name: 'Aksjonærregisteroppg.',
				type: 'GENERAL',
				defaultActive: true,
				notifications: true,
				globalRoutineFilename: 'aksjonaerregisteroppgave.md',
				frequency: 'yearly',
				deadline: {
					day: 31, // 31. january
					month: 0,
				},
				subTasks: [
					{
						name: 'Kontroller at vedtatte utbytter er tatt med på oppgaven',
					},
					{ name: 'Kontroller at aksjonærene stemmer' },
					{
						name: 'Kontroller at kapitalendringer er hensyntatt (emisjoner, fisjoner osv.)',
					},
					{
						name: 'Kontroller at ulovlig lån blir innrapportert i oppgaven',
					},
				],
			},
			{
				name: 'Avholdt Generalforsamling',
				type: 'GENERAL',
				defaultActive: true,
				notifications: false,
				globalRoutineFilename: 'avholdt-generalforsamling.md',
				frequency: 'yearly',
				deadline: {
					day: 30, // 30. june
					month: 5,
				},
				subTasks: [],
			},
			{
				name: 'Kårytelser',
				type: 'GENERAL',
				defaultActive: false,
				notifications: false,
				globalRoutineFilename: 'karytelser.md',
				frequency: 'yearly',
				deadline: {
					day: 5, // 5. january
					month: 0,
				},
				subTasks: [
					{
						name: 'Kontroller leieverdi på kår etter sjablongverdier i takseringsreglene §3-2-4',
					},
					{
						name: 'Er det flere kårmottakere? Sjablongverdien deles på antallet kårmottakere.',
					},
					{
						name: 'Uttak ved til kår? Norm er 2 favner multiplisert med takst i henhold til takseringsreglene §3-2-6',
					},
				],
			},
		],
	},
	{
		category: { name: 'Lønn', order: 2 },
		tasks: [
			{
				name: 'Lønnskjøring',
				type: 'GENERAL',
				defaultActive: false,
				notifications: true,
				globalRoutineFilename: 'lonnskjoring.md',
				frequency: 'monthly',
				deadline: {
					day: 20,
				},
				subTasks: [
					{ name: 'Dato for når lønnskjøring skal utføres' },
					{ name: 'Kontroller dokumentasjon på lønnsskjøring' },
					{ name: 'Oppdater skattekort' },
					{ name: 'Oppdater OTP' },
					{ name: 'Skattetrekk, utleggstrekk og andre trekk' },
					{ name: 'Trekk fagforeningskontingent' },
					{
						name: 'Skal det sendes inn inntekt og skatteopplysninger til NAV?',
					},
					{ name: 'Fri telefon/andre innberetningspliktige ytelser' },
					{ name: 'Refusjon sykepenger/andre refusjoner' },
					{ name: 'Yrkesskadeforsikring' },
					{
						name: 'Kontroller at alle ansatte er aktive, meld ut de som har sluttet',
					},
				],
			},
			{
				name: 'A-melding',
				type: 'GENERAL',
				defaultActive: false,
				notifications: true,
				globalRoutineFilename: 'a-melding.md',
				frequency: 'monthly',
				deadline: {
					day: 5,
				},
				subTasks: [{ name: 'Kontroller at A-melding blir sendt inn' }],
			},
			{
				name: 'Avstemt arbeidsgiveravgift',
				type: 'GENERAL',
				defaultActive: false,
				notifications: false,
				globalRoutineFilename: 'avstemt-arbeidsgiveravgift.md',
				subTasks: [
					{ name: 'Avstemt arbeidsgiveravgift' },
					{ name: 'Kontroller arbeidsgiveravgiftssone' },
				],
			},
			{
				name: 'Avstemt skattetrekk',
				type: 'GENERAL',
				defaultActive: false,
				notifications: false,
				globalRoutineFilename: 'avstemt-skattetrekk.md',
				subTasks: [
					{
						name: 'Avstemt skattetrekk i avstemmingsskjema mot hovedbok',
					},
					{
						name: 'Blir avsatt skattetrekk ført over på skattetrekkskonto?',
					},
				],
			},
			{
				name: 'Betalt skattetrekk og arb.g.avg.',
				type: 'GENERAL',
				defaultActive: false,
				notifications: true,
				globalRoutineFilename: 'betalt-skattetrekk-og-arb-g-avg.md',
				frequency: 'biMonthly',
				deadline: {
					day: 15,
					relativeMonth: 1,
				},
				subTasks: [
					{
						name: 'Sjekk at arbeidsgiveravgift og skattetrekk er betalt inn',
					},
				],
			},
			{
				name: 'Feriepenger',
				type: 'GENERAL',
				defaultActive: false,
				notifications: false,
				globalRoutineFilename: 'feriepenger.md',
				subTasks: [
					{ name: 'Kontroller feriepengeliste mot hovedbok' },
					{
						name: 'Kontroller avsetning arbeidsgiveravg. mot avsatt feriepenger',
					},
				],
			},
			{
				name: 'Sammenstillingsoppg.',
				type: 'GENERAL',
				defaultActive: false,
				notifications: false,
				globalRoutineFilename: 'sammenstillingsoppgave.md',
				frequency: 'yearly',
				deadline: {
					day: 31, // 31. january
					month: 0,
				},
				subTasks: [
					{
						name: 'Send ut sammenstillingsoppgave til alle som har mottatt lønn',
					},
				],
			},
		],
	},
	{
		category: { name: 'Anleggsmidler', order: 3 },
		tasks: [
			{
				name: 'Immaterielle eiendeler',
				type: 'GENERAL',
				defaultActive: true,
				notifications: false,
				globalRoutineFilename: 'immaterielle-eiendeler.md',
				subTasks: [
					{
						name: 'Kontroller grunnlaget for bokføring av goodwill',
					},
					{
						name: 'Kontroller grunnlaget for bokføring av utsatt skattefordel',
					},
					{
						name: 'Varemerker, patenter, programvare, lisenser, utgiverrettigheter osv.',
					},
				],
			},
			{
				name: 'Varige driftsmidler',
				type: 'GENERAL',
				defaultActive: true,
				notifications: false,
				globalRoutineFilename: 'varige-driftsmidler.md',
				subTasks: [
					{
						name: 'Kontroller tilganger, avganger - mva. og gevinst/tap',
					},
					{
						name: 'Er driftsmidlene fysisk tilstede?',
					},
					{
						name: 'Kontroller at driftsmidlene ikke er overvurdert',
					},
				],
			},
			{
				name: 'Finansielle anleggsmidler',
				type: 'GENERAL',
				defaultActive: true,
				notifications: false,
				globalRoutineFilename: 'finansielle-anleggsmidler.md',
				subTasks: [
					{
						name: 'Kontroller om aksjene/verdipapirene er innenfor fritaksmetoden',
					},
					{
						name: 'Kontroller virkelig-, mot bokført verdi - nedskrivningsplikt',
					},
					{
						name: 'Kontroller gevinst/tap',
					},
					{
						name: 'Kontroller klassifisering',
					},
					{
						name: 'Kontroller likningsverdi',
					},
				],
			},
			{
				name: 'Langsiktige fordringer',
				type: 'GENERAL',
				defaultActive: true,
				notifications: false,
				globalRoutineFilename: 'langsiktige-fordringer.md',
				subTasks: [
					{
						name: 'Avstem mellomværende konsern',
					},
					{
						name: 'Avstem forskuddsleasing',
					},
					{
						name: 'Avstem andre langsiktige fordringer',
					},
				],
			},
		],
	},
	{
		category: { name: 'Omløpsmidler', order: 4 },
		tasks: [
			{
				name: 'Varelager',
				type: 'GENERAL',
				defaultActive: true,
				notifications: false,
				globalRoutineFilename: 'varelager.md',
				subTasks: [
					{ name: 'Kontroller vurderingsprinispp' },
					{
						name: 'Kontroller at varelageret er fysisk tilstede/har selskapet varer i kommisjon',
					},
					{ name: 'Kontroller at det er vurdert ukurans' },
					{ name: 'Kontroller bruttofortjeneste' },
					{ name: 'Kontroller varetellingslistene' },
					{ name: 'Vurder om lageret er overvurdert' },
				],
			},
			{
				name: 'Varer i arbeid - prosjektbedrifter',
				type: 'GENERAL',
				defaultActive: true,
				notifications: false,
				globalRoutineFilename: 'varer-i-arbeid-prosjektbedrifter.md',
				subTasks: [
					{
						name: 'Snakk med kunden om de har prosjekter som går over nytt år',
					},
					{
						name: 'Gå gjennom prosjektregnskapene sammen med kunden',
					},
					{ name: 'Kontroller a-kontofakturering' },
					{ name: 'Vurder evt. tap på pågående prosjekter' },
				],
			},
			{
				name: 'Kundefordringer',
				type: 'GENERAL',
				defaultActive: true,
				notifications: false,
				globalRoutineFilename: 'kundefordringer.md',
				subTasks: [
					{ name: 'Kontroller åpne poster mot hovedbok' },
					{ name: 'Kontroller tapsavsetningen' },
					{ name: 'Kontroller kreditposter' },
					{ name: 'Kontroller gamle poster' },
					{
						name: 'Kontroller periodisering av salg rundt årskifte',
					},
				],
			},
			{
				name: 'Andre fordringer',
				type: 'GENERAL',
				defaultActive: true,
				notifications: false,
				globalRoutineFilename: 'andre-fordringer.md',
				subTasks: [
					{ name: 'Dokumenter lån til ansatte' },
					{ name: 'Er det gitt lån i strid med aksjeloven?' },
					{ name: 'Kontroller klassifisering' },
					{ name: 'Avstem mellomværende i konsern' },
				],
			},
			{
				name: 'Andre forskuddsbetalte kostnader',
				type: 'GENERAL',
				defaultActive: true,
				notifications: false,
				globalRoutineFilename: 'andre-forskuddsbetalte-kostnader.md',
				subTasks: [
					{
						name: 'Kontroller og avstem forskuddsbetalte kostnader',
					},
					{ name: 'Kontroller og avstem forskuddsleasing' },
				],
			},
			{
				name: 'Aksjer og verdipapirer',
				type: 'GENERAL',
				defaultActive: true,
				notifications: false,
				globalRoutineFilename: 'aksjer-og-verdipapirer.md',
				subTasks: [
					{
						name: 'Kontroller at gevinster og tap er korrekt beregnet',
					},
					{
						name: 'Kontroller om aksjene og verdipapirene er innenfor fritaksmetoden',
					},
					{
						name: 'Kontroller klassifisering av aksjene og verdipapirene herunder korrekt verdsettelse av postene',
					},
					{
						name: 'Kontroller at det benyttes riktig ligningsverdi',
					},
				],
			},
			{
				name: 'Bank',
				type: 'GENERAL',
				defaultActive: true,
				notifications: false,
				globalRoutineFilename: 'bank.md',
				subTasks: [
					{ name: 'Kontroller årsoppgave mot bankkontoene' },
					{
						name: 'Kontroller kassebeholdningen og at den er signert og datert',
					},
					{
						name: 'Kontroller riktig klassifisering av kassekreditt',
					},
					{ name: 'Kontroller at alle konti er avstemt' },
				],
			},
		],
	},
	{
		category: { name: 'Egenkapital', order: 5 },
		tasks: [
			{
				name: 'Aksjekapital og egenkapital',
				type: 'GENERAL',
				defaultActive: true,
				notifications: false,
				globalRoutineFilename: 'aksjekapital-og-egenkapital.md',
				subTasks: [
					{
						name: 'Har selskapet forsvarlig egenkapital - aksjekapitalen tapt?',
					},
					{ name: 'Har selskapet egne aksjer?' },
					{ name: 'Kontroller egenkapitaltransaksjoner' },
					{
						name: 'Er feil fra tidligere år ført direkte mot egenkapitalen?',
					},
				],
			},
		],
	},
	{
		category: { name: 'Avsetning og forpliktelser', order: 6 },
		tasks: [
			{
				name: 'Andre avsetninger',
				type: 'GENERAL',
				defaultActive: true,
				notifications: false,
				globalRoutineFilename: 'andre-avsetninger.md',
				subTasks: [
					{ name: 'Kontroller utsatt skatt' },
					{
						name: 'Har selskapet andre avsetninger det må tas hensyn til?',
					},
				],
			},
		],
	},
	{
		category: { name: 'Langsiktig gjeld', order: 7 },
		tasks: [
			{
				name: 'Langsiktig gjeld til kredittinstitusjoner',
				type: 'GENERAL',
				defaultActive: true,
				notifications: false,
				globalRoutineFilename:
					'langsiktig-gjeld-til-kredittinstitusjoner.md',
				subTasks: [
					{ name: 'Kontroller mot årsoppgave' },
					{ name: 'Kontroller riktig klassifisering' },
					{ name: 'Kontroller at påløpt rente er hensyntatt' },
				],
			},
			{
				name: 'Annen langsiktig gjeld',
				type: 'GENERAL',
				defaultActive: true,
				notifications: false,
				globalRoutineFilename: 'annen-langsiktig-gjeld.md',
				subTasks: [
					{
						name: 'Innhent låneavtaler fra aksjonærer og andre lånegivere',
					},
					{ name: 'Kontroller renteberegning' },
					{
						name: 'Pensjonsforpliktelser',
					},
				],
			},
		],
	},
	{
		category: { name: 'Kortsiktig gjeld', order: 8 },
		tasks: [
			{
				name: 'Kortsiktig gjeld til kredittinstitusjoner',
				type: 'GENERAL',
				defaultActive: true,
				notifications: false,
				globalRoutineFilename:
					'kortsiktig-gjeld-til-kredittinstitusjoner.md',
				subTasks: [
					{
						name: 'Kontroller at kassekreditt er riktig klassifisert',
					},
				],
			},
			{
				name: 'Leverandørgjeld',
				type: 'GENERAL',
				defaultActive: true,
				notifications: false,
				globalRoutineFilename: 'leverandorgjeld.md',
				subTasks: [
					{ name: 'Innhent kontoutdrag på de største leverandørene' },
					{
						name: 'Kontroller debetsaldoer og gamle poster ved innhenting av kontoutdrag',
					},
					{
						name: 'Kontroller riktig cut off ved årskifte på leverandørene',
					},
					{
						name: 'Kontroller bilag på nytt år for riktig periodisering',
					},
				],
			},
			{
				name: 'Forskuddsskatt',
				type: 'GENERAL',
				defaultActive: true,
				notifications: false,
				globalRoutineFilename: 'forskuddsskatt.md',
				subTasks: [{ name: 'Forskuddsskatten endres' }],
			},
			{
				name: 'Betalbar skatt',
				type: 'GENERAL',
				defaultActive: true,
				notifications: false,
				globalRoutineFilename: 'betalbar-skatt.md',
				frequency: 'yearly',
				deadline: {
					day: 30, //30. june
					month: 5,
				},
				subTasks: [
					{ name: 'Betalbar skatt kontrolleres' },
					{
						name: 'Kontroller skatteoppgjør mot innsendt skattemelding ',
					},
				],
			},

			{
				name: 'Annen kortsiktig gjeld',
				type: 'GENERAL',
				defaultActive: true,
				notifications: false,
				globalRoutineFilename: 'annen-kortsiktig-gjeld.md',
				subTasks: [
					{ name: 'Kontroller påløpte kostnader' },
					{
						name: 'Kontroller at bonuser, reklamasjoner og andre avsetninger er hensyntatt',
					},
					{ name: 'Kontroller skyldig lønn' },
				],
			},
			{
				name: 'Andre opplysninger',
				type: 'GENERAL',
				defaultActive: true,
				notifications: false,
				globalRoutineFilename: 'andre-opplysninger.md',
				subTasks: [
					{
						name: 'Spør daglig leder om det er andre forpliktelser som bør hensynstas',
					},
					{
						name: 'Spør daglig leder om det er hendelser etter balansedag som skal hensynstas',
					},
				],
			},
		],
	},
	{
		category: { name: 'Resultat', order: 9 },
		tasks: [
			{
				name: 'Salg',
				type: 'GENERAL',
				defaultActive: true,
				notifications: false,
				globalRoutineFilename: 'salg.md',
				subTasks: [
					{ name: 'Bruttofortjenesteanalyse' },
					{
						name: 'Nummerrekkefølge der kunder fakturerer utenfor integrert system',
					},
					{
						name: 'Utarbeid sammenligningsanalyse mot tidligere år, evt samme bransje',
					},
					{ name: 'Kontroller avgiftsbehandlingen' },
					{ name: 'I eiendomsselskap avstemmes leieinntektene' },
					{ name: 'Periodisering inntekter' },
					{ name: 'Gevinst ved salg av anleggsmidler' },
					{ name: 'Faktura i samsvar med ordregrunnlag' },
				],
			},
			{
				name: 'Varekjøp',
				type: 'GENERAL',
				defaultActive: true,
				notifications: false,
				globalRoutineFilename: 'varekjop.md',
				subTasks: [
					{ name: 'Kontroller mva. behandling' },
					{ name: 'Kontroller riktig cut-off mot varelager' },
					{
						name: 'Kontroller bokføring på deklarasjonsoversikt fra toll',
					},
				],
			},
			{
				name: 'Tredjepartsopplysninger',
				type: 'GENERAL',
				defaultActive: true,
				notifications: false,
				globalRoutineFilename: 'tredjepartsopplysninger.md',
				frequency: 'yearly',
				deadline: {
					day: 15, //15. february
					month: 1,
				},
				subTasks: [
					{
						name: 'Kontroller at det er sendt inn tredjepartsopplysninger for selvstendig næringsdrivende',
					},
				],
			},
			{
				name: 'Avskrivninger',
				type: 'GENERAL',
				defaultActive: true,
				notifications: false,
				globalRoutineFilename: 'avskrivninger.md',
				subTasks: [
					{
						name: 'Kontroller at avskrivningene blir bokført på riktig periode',
					},
				],
			},
			{
				name: 'Andre driftskostnader',
				type: 'GENERAL',
				defaultActive: true,
				notifications: false,
				globalRoutineFilename: 'andre-driftskostnader.md',
				subTasks: [
					{
						name: 'Totalavstem store faste kostnader som leasing og husleie',
					},
					{ name: 'Kontroller bokføring av sensitive poster' },
					{ name: 'Kontroller mva. behandling' },
					{
						name: 'Kontroller kontoer som avviker mye fra tidligere år',
					},
				],
			},
			{
				name: 'Finansinntekter',
				type: 'GENERAL',
				defaultActive: true,
				notifications: false,
				globalRoutineFilename: 'finansinntekter.md',
				subTasks: [
					{ name: 'Kontroller renteinntekter mot årsoppgaver' },
					{ name: 'Kontroller andre renteinntekter mot avtaler' },
					{
						name: 'Kontroller riktig behandling av finansinntekter innenfor og utenfor EØS',
					},
					{ name: 'Behandling av utbytte/konsernbidrag' },
				],
			},
			{
				name: 'Finanskostnader',
				type: 'GENERAL',
				defaultActive: true,
				notifications: false,
				globalRoutineFilename: 'finanskostnader.md',
				subTasks: [
					{ name: 'Kontroller rentekostnader mot årsoppgaver' },
					{ name: 'Kontroller andre rentekostnader mot avtaler' },
					{
						name: 'Kontroller riktig behandling av finanskostnader innenfor og utenfor EØS',
					},
				],
			},
			{
				name: 'IB-kontroll',
				type: 'GENERAL',
				defaultActive: true,
				notifications: false,
				globalRoutineFilename: 'ib-kontroll.md',
				frequency: 'yearly',
				deadline: {
					day: 30, // 30. june
					month: 5,
				},
				subTasks: [
					{
						name: 'Kontroller at tilleggsposteringer er postert på nytt år',
					},
				],
			},
			{
				name: 'Skattemelding',
				type: 'TAX_NOTICE',
				defaultActive: true,
				notifications: true,
				globalRoutineFilename: 'skattemelding.md',
				frequency: 'yearly',
				deadline: {
					day: 31, // 31. may
					month: 4,
				},
				subTasks: [],
			},
			{
				name: 'Årsregnskap',
				defaultActive: true,
				notifications: true,
				type: 'ANNUAL_ACCOUNTS',
				globalRoutineFilename: 'arsregnskap.md',
				frequency: 'yearly',
				deadline: {
					day: 30, // 30. june
					month: 5,
				},
				subTasks: [],
			},
			{
				name: 'Planlagt levert revisor',
				defaultActive: false,
				notifications: false,
				type: 'GENERAL',
				globalRoutineFilename: 'planlagt-levert-revisor.md',
				frequency: 'yearly',
				deadline: {
					day: 30, // 30. march
					month: 2,
				},
				subTasks: [],
			},
			{
				name: 'Rådgivning',
				type: 'GENERAL',
				defaultActive: true,
				notifications: false,
				globalRoutineFilename: null,
				subTasks: [
					{
						name: 'Gjennomgang finansiering - rentebetingelser og arbeidskapital',
					},
					{ name: 'Gjennomgang av prisfastsettelser og lønnsomhet' },
					{ name: 'Gjennomgang forsikringsordninger' },
					{
						name: 'Rutiner for regnskapsføring, riktig regnskapssystem og moduler',
					},
					{ name: 'Formuesverdi aksjer' },
					{ name: 'Formuesverdi eiendom' },
					{ name: 'Mva.' },
					{ name: 'Arv' },
				],
			},
			{
				name: 'Oppdragskontroll, oppdragsansvarlig',
				type: 'GENERAL',
				defaultActive: true,
				notifications: false,
				globalRoutineFilename:
					'oppdragskontroll-og-kvalitetssikring-oppdragsansvarlig.md',
				frequency: 'yearly',
				deadline: {
					day: 31, // 31. october
					month: 9,
				},
				subTasks: [
					{ name: 'Oppdragsavtale og fullmakter er oppdatert' },
					{
						name: 'Kravene til forståelse av oppdragsgivers virksomhet og vurdering av oppdragsgivers interne rutiner er oppfylt',
					},
					{
						name: 'Kvalitetssikring av rapporteringsgrunnlaget er tilfredsstillende utført',
					},
					{
						name: 'Pliktig regnskapsrapportering er gjennomført i henhold til lovkrav',
					},
					{
						name: 'Periodisk regnskapsrapportering til oppdragsgiver er gjennomført i henhold til avtale',
					},
					{
						name: 'Eventuelle svakheter ved oppdragsgivers interne rutiner er rapportert til oppdragsgiver',
					},
					{
						name: 'Eventuelle brudd på lovkrav er rapportert til oppdragsgiver',
					},
					{
						name: 'Oppdragsdokumentasjonen er tilfredsstillende og à jour',
					},
				],
			},
			{
				name: 'Pliktig rapportering ifølge bokføringsloven',
				type: 'GENERAL',
				defaultActive: true,
				notifications: false,
				globalRoutineFilename:
					'pliktig-rapportering-ifolge-bokforingsloven.md',
				subTasks: [{ name: 'Lås perioder' }],
			},
		],
	},
];

export const taskEntryStatuses = {
	GENERAL: ['Ikke aktuell', 'Utført', 'Kontrollert'],
	TAX_NOTICE: [
		'Klart for utarbeidelse',
		'Venter på opplysninger',
		'Internrevisjon',
		'Ferdig utarbeidet, venter opplysninger',
		'Ferdig utarbeidet',
		'Sendt til revisor',
		'Sendt til signering',
		'Venter på signering revisor',
		'Ferdig signert og godkjent Altinn',
		'Kontrollert skatteoppgjør',
	],
	ANNUAL_ACCOUNTS: [
		'Klart for utarbeidelse',
		'Venter på opplysninger',
		'Internrevisjon',
		'Ferdig utarbeidet, venter opplysninger',
		'Ferdig utarbeidet',
		'Sendt til revisor',
		'Sendt til signering',
		'Venter på revisors beretning',
		'Ferdig signert',
		'Sendt til Altinn',
		'Godkjent Brreg',
	],
};

export const taskEntryCompletedStatuses = [
	'Ikke aktuell',
	'Utført',
	'Kontrollert',
	'Ferdig signert og godkjent Altinn',
	'Kontrollert skatteoppgjør',
	'Ferdig signert',
	'Sendt til Altinn',
	'Godkjent Brreg',
];

export const taskEntryPostponedStatuses = {
	GENERAL: null,
	ANNUAL_ACCOUNTS: [
		'Vi søker utsettelse',
		'Kunden søker utsettelse',
		'Revisor søker utsettelse',
	],
	TAX_NOTICE: [
		'Vi søker utsettelse',
		'Kunden søker utsettelse',
		'Revisor søker utsettelse',
	],
};

// Web modals
import EmailVerifyModal from 'web/components/verifications/EmailVerifyModal';
import PhoneVerifyModal from 'web/components/verifications/PhoneVerifyModal';
import AddressVerifyModal from 'web/components/verifications/AddressVerifyModal';
import CitizenshipVerifyModal from 'web/components/verifications/CitizenshipVerifyModal';
import PEPVerifyModal from 'web/components/verifications/PEPVerifyModal';
import UserDetailsVerifyModal from 'web/components/verifications/UserDetailsVerifyModal';

export const webComponentsLookup = {
	email: EmailVerifyModal,
	phone: PhoneVerifyModal,
	address: AddressVerifyModal,
	citizenship: CitizenshipVerifyModal,
	isPoliticallyExposedPerson: PEPVerifyModal,
	detailsVerify: UserDetailsVerifyModal,
};

export const verificationKeys = [
	'email',
	'phone',
	'address',
	'citizenship',
	'isPoliticallyExposedPerson',
];

export function getVerificationOperation(user, type, value) {
	const operations = {
		email: {
			type: 'email',
			props: {
				_id: user._id,
				verificationEmail: value || user.email,
			},
		},
		phone: {
			type: 'phone',
			props: {
				_id: user._id,
				verificationPhone: value || user.phone,
			},
		},
		address: {
			type: 'address',
			props: {
				_id: user._id,
				verificationAddress: {
					street_address: user?.address?.street_address,
					postal_code: user?.address?.postal_code,
					locality: user?.address?.locality,
					country: user?.address?.country,
				},
			},
		},
		citizenship: {
			type: 'citizenship',
			props: {
				_id: user._id,
				verificationCitizenship: user?.citizenship,
			},
		},
		isPoliticallyExposedPerson: {
			type: 'isPoliticallyExposedPerson',
			props: {
				_id: user._id,
			},
		},
	};

	return operations[type] || null;
}
